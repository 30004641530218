.mr-10 {
  margin-right: 10px;
}

.z-1000 {
  z-index: 1000;
}

.modal {
  background: rgba(0, 0, 0, 0.3);
}

.button-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #198754 !important;
  color: white !important;
  padding: 4px 8px !important;
  margin: 6px !important;
}

.button-table:hover {
  background-color: #157347 !important;
}
