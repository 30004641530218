.footer-container {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #4c4c4c;
  /* text-align: center; */
  color: #fff;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

p {
  margin: 0px !important;
}