.wrapper-table {
  margin: auto;
  margin-top: 30px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  /* border: 2px solid black; */
  border-radius: 5px;
}

.none-button {
  border-style: none;
  
}

.table {
  border: 3px solid rgb(209, 209, 209);
}