.form-container {
  width: 80%;
  margin: 15px auto;
  border: 1px solid black;
  padding: 10px;
}

.row {
  margin-top: 5px;
}
.button-wrapper {
  display: flex;
  margin-top: 30px;
  margin-bottom: 15px;
}
.form-bottom {
  border-top: 1px solid black;
  margin-top: 15px;
}

.error-block {
  color: red !important;
  font-size: 12px;
  display: block;
  margin: 2px 0px 0px 5px;
}