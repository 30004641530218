#login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0 2rem;
}

#login .form {
    background-color: #f1f1f1;
    width: 100%;
    max-width: 480px;
    padding: 0.5rem;
}

#login .field {
    padding-bottom: 0.5rem;
}

#login .field label {
    display: block;
}

#login .field input {
    width: 100%;
}

#login .actions {
    text-align: center;
}